var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"testeeDetail"}},[_c('div',{staticClass:"testee-centreOrAdd clearfix"},[_c('span',{staticClass:"testee-centre fl"},[_vm._v("研究中心：")]),_c('el-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.centreOptions.length >= 1),expression:"centreOptions.length >= 1"}],staticClass:"fl",attrs:{"placeholder":"请选择","size":"mini"},on:{"change":_vm.centerChange},model:{value:(_vm.centre),callback:function ($$v) {_vm.centre=$$v},expression:"centre"}},_vm._l((_vm.centreOptions),function(item){return _c('el-option',{key:item.centerKey,attrs:{"label":item.centerName,"value":item.centerKey}})}),1),_c('el-form',{staticClass:"demo-form-inline",staticStyle:{"float":"right"},attrs:{"inline":true}},[_c('el-form-item',[_c('el-input',{staticClass:"input-with-select",staticStyle:{"margin-top":"6px"},attrs:{"placeholder":"请输入内容","size":"mini"},model:{value:(_vm.search.queryVal),callback:function ($$v) {_vm.$set(_vm.search, "queryVal", $$v)},expression:"search.queryVal"}},[_c('el-select',{staticStyle:{"width":"100px"},attrs:{"slot":"prepend","placeholder":"请选择"},slot:"prepend",model:{value:(_vm.search.selectOption),callback:function ($$v) {_vm.$set(_vm.search, "selectOption", $$v)},expression:"search.selectOption"}},[_c('el-option',{attrs:{"label":"受试者编号","value":"outpatientNumber"}}),_vm._l((_vm.testeeDetail),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.pName,"value":item.pCode}})})],2),_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"suffix"},on:{"click":_vm.searchTestee},slot:"suffix"})],1)],1),_c('el-form-item',[_c('el-button',{directives:[{name:"authorizeRole",rawName:"v-authorizeRole",value:({
            itemCode: 'testee_add',
            menuContainer: _vm.$authorContainer,
          }),expression:"{\n            itemCode: 'testee_add',\n            menuContainer: $authorContainer,\n          }"}],staticClass:"testee-add fr",attrs:{"size":"small","type":"primary"},on:{"click":_vm.testeeAdd}},[_vm._v("添加受试者 ")])],1)],1)],1),_c('div',{staticClass:"testee-content"},[_c('div',{staticStyle:{"width":"calc(100% - 20px)","position":"absolute","top":"16px","bottom":"70px","overflow-y":"auto"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.testeeData,"border":"","size":"medium","header-cell-class-name":_vm.tableHeaderCn,"row-class-name":_vm.tableRowCn}},[_c('el-table-column',{attrs:{"prop":"outpatientNumber","align":"center","label":"受试者编号"}}),_vm._l((_vm.cols),function(col,index){return [_c('el-table-column',{key:col.prop,attrs:{"prop":col.prop,"label":col.label,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(typeof scope.row[col.prop] != 'object')?_c('span',{key:index},[_vm._v(_vm._s(_vm.formatterValue(scope.row, col.prop)))]):(scope.row[col.prop] == null)?_c('span',{key:index},[_vm._v("-")]):(scope.row[col.prop].length === 1)?_c('span',{key:index,on:{"click":function($event){return _vm.toHandleCommand(scope.row[col.prop], scope.row)}}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":'最近更新时间:' + scope.row[col.prop][0].updateTime,"placement":"bottom-start"}},[(scope.row[col.prop][0].type === '1')?[(
                        _vm.groupPatientStatus(
                          scope.row['groupPatientList'],
                          scope.row[col.prop][0].groupConfigLists
                        )
                      )?[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-yitiaoguo"}})])]:[(scope.row[col.prop][0].actualTime)?_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-yiluru"}})]):_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-weikaishi"}})])]]:_vm._e(),(scope.row[col.prop][0].type === '0')?[(scope.row[col.prop][0].actualTime)?_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-yiluru"}})]):_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-weikaishi"}})])]:_vm._e(),(scope.row[col.prop][0].type === '2')?[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-yiluru"}})])]:_vm._e()],2)],1):_c('el-dropdown',{attrs:{"trigger":"click","size":"small"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[(_vm.showImage(scope.row[col.prop]))?_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-yiluru"}})]):_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-weikaishi"}})]),_c('span',[_vm._v("×"+_vm._s(scope.row[col.prop].length))])]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((scope.row[col.prop]),function(item,num){return _c('el-dropdown-item',{key:item.exampleId,attrs:{"command":_vm.composeValue(item, scope.row)}},[_vm._v(" "+_vm._s(item.displayNameCn + (num + 1) + " (最近更新时间:" + item.updateTime + ")")+" ")])}),1)],1)]}}],null,true)})]}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"edit-wrap"},[_c('el-dropdown',{directives:[{name:"authorizeRole",rawName:"v-authorizeRole",value:({
                  itemCode: 'visit_not_plan_multiple_add',
                  menuContainer: _vm.$authorContainer,
                }),expression:"{\n                  itemCode: 'visit_not_plan_multiple_add',\n                  menuContainer: $authorContainer,\n                }"}],attrs:{"trigger":"click","size":"small"},on:{"visible-change":_vm.getRepeatTestee,"command":_vm.addRepeatTestee}},[_c('span',{staticClass:"el-dropdown-link"},[_c('el-button',{staticClass:"table-btn",attrs:{"type":"text","size":"small"}},[_c('i',{staticClass:"iconfont icon-fuzhi",attrs:{"title":"添加可重复访视"}})])],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.addRepeatTesteeList),function(item){return _c('el-dropdown-item',{key:item.planCode,attrs:{"command":_vm.composeValue(item, scope.row)}},[_vm._v(_vm._s(item.displayNameCn)+" ")])}),1)],1),_c('el-button',{directives:[{name:"authorizeRole",rawName:"v-authorizeRole",value:({
                  itemCode: 'testee_del',
                  menuContainer: _vm.$authorContainer,
                }),expression:"{\n                  itemCode: 'testee_del',\n                  menuContainer: $authorContainer,\n                }"}],staticClass:"table-btn",attrs:{"type":"text","size":"small"}},[_c('el-popconfirm',{attrs:{"title":"您确定要删除该受试者吗？"},on:{"confirm":function($event){return _vm.testeeDelete(scope.row)}}},[_c('i',{staticClass:"iconfont icon-shanchu",attrs:{"slot":"reference","title":"删除"},slot:"reference"})])],1),_c('el-button',{directives:[{name:"authorizeRole",rawName:"v-authorizeRole",value:({
                  itemCode: 'crf_data_sdv',
                  menuContainer: _vm.$authorContainer,
                }),expression:"{\n                  itemCode: 'crf_data_sdv',\n                  menuContainer: $authorContainer,\n                }"}],staticClass:"table-btn",attrs:{"type":"text"},on:{"click":function($event){return _vm.toHandleCommand(scope.row[_vm.cols[0].prop], scope.row, 'SDV')}}},[_vm._v("SDV ")]),(_vm.$route.query.wx != 0)?_c('el-button',{staticStyle:{"font-size":"18px","color":"#2d5c88"},attrs:{"type":"text","icon":"el-icon-full-screen"},on:{"click":function($event){return _vm.getQrCode(scope.row, scope.$index)}}}):_vm._e(),(_vm.$route.query.wx != 0)?_c('el-button',{staticStyle:{"font-size":"18px","color":"#2d5c88"},attrs:{"type":"text","icon":" el-icon-reading"},on:{"click":function($event){return _vm.getLookCode(scope.row, scope.$index)}}}):_vm._e()],1)]}}])})],2)],1),_c('el-pagination',{staticClass:"research-pagination",attrs:{"current-page":_vm.pagination.currentPage,"page-sizes":[10, 20, 30, 40],"page-size":_vm.pagination.size,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.pagination.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('el-dialog',{attrs:{"title":"添加受试者","visible":_vm.dialogVisibleAdd,"width":"35%","custom-class":"testee-dialog"},on:{"close":function($event){return _vm.handleClose('testeeFormAdd')},"update:visible":function($event){_vm.dialogVisibleAdd=$event},"open":function($event){_vm.submitButtonDisabled = false}}},[_c('el-form',{ref:"testeeFormAdd",attrs:{"size":"mini","model":_vm.testeeForm,"rules":_vm.testeeRulesAdd,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"受试者编号","label-width":"100px","prop":"outpatientNumber"}},[_c('el-input',{staticStyle:{"width":"85%"},attrs:{"disabled":_vm.addTesteeType === 1,"placeholder":_vm.addTesteeType === 1
              ? '您已选择系统生成，无需填写'
              : '请输入受试者编号'},model:{value:(_vm.testeeForm.outpatientNumber),callback:function ($$v) {_vm.$set(_vm.testeeForm, "outpatientNumber", $$v)},expression:"testeeForm.outpatientNumber"}})],1),_vm._l((_vm.testeeDetail),function(item){return _c('el-form-item',{key:item.pCode,attrs:{"label":item.pName,"label-width":"100px","prop":item.pCode}},[(item.pCode === 'sex')?_c('el-radio-group',{model:{value:(_vm.testeeForm[item.pCode]),callback:function ($$v) {_vm.$set(_vm.testeeForm, item.pCode, $$v)},expression:"testeeForm[item.pCode]"}},[_c('el-radio',{attrs:{"label":"01"}},[_vm._v("男")]),_c('el-radio',{attrs:{"label":"02"}},[_vm._v("女")])],1):(item.pCode === 'patientRelation')?_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.testeeForm[item.pCode]),callback:function ($$v) {_vm.$set(_vm.testeeForm, item.pCode, $$v)},expression:"testeeForm[item.pCode]"}},_vm._l((_vm.patientRelationSelect),function(item,index){return _c('el-option',{key:index,attrs:{"label":item,"value":index}})}),1):(
            item.pCode === 'loginTime' ||
            item.pCode === 'dob' ||
            item.pCode === 'admissionDate'
          )?_c('el-date-picker',{attrs:{"type":"date","placeholder":"请选择日期","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.testeeForm[item.pCode]),callback:function ($$v) {_vm.$set(_vm.testeeForm, item.pCode, $$v)},expression:"testeeForm[item.pCode]"}}):_c('el-input',{staticStyle:{"width":"85%"},attrs:{"placeholder":'请输入' + item.pName},model:{value:(_vm.testeeForm[item.pCode]),callback:function ($$v) {_vm.$set(_vm.testeeForm, item.pCode, $$v)},expression:"testeeForm[item.pCode]"}}),(item.pCode === 'nameAcronym')?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","popper-class":"testeeAddPopper","content":"受试者姓名缩写，按照国家汉语拼音标准方案填写受试者的拼音缩写；两字姓名填写前两个字母；三字姓名填写三字首字母及第三字的第二个字母，四字姓名填写每个字的首字母。如张君ZHJU，王小鹏WXPE，欧阳小雅OYXY；如果不符合上述规则，相应字母位置可用“-”代替。","placement":"bottom-end"}},[_c('span',{staticStyle:{"display":"inline-block","width":"14px","height":"14px","line-height":"14px","text-align":"center","font-size":"12px","border-radius":"7px","background":"#ccc","color":"#fff","margin-left":"4px"}},[_vm._v("?")])]):_vm._e()],1)}),_vm._l((_vm.testeeForm.testeeGroup),function(item,index){return _c('el-form-item',{key:item.groupName,attrs:{"prop":'testeeGroup.' + index + '.groupValue',"rules":[
          {
            required: true,
            message: '请选择' + item.groupName,
            trigger: 'blur,change',
          } ],"label":item.groupName,"label-width":"100px"}},[_c('el-select',{attrs:{"placeholder":'请选择' + item.groupName},model:{value:(item.groupValue),callback:function ($$v) {_vm.$set(item, "groupValue", $$v)},expression:"item.groupValue"}},_vm._l((item.data),function(groupItem){return _c('el-option',{key:groupItem.code,attrs:{"label":groupItem.dictName,"value":groupItem.code}})}),1)],1)})],2),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){_vm.dialogVisibleAdd = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"mini","type":"primary","disabled":_vm.submitButtonDisabled,"loading":_vm.btnLoading},on:{"click":function($event){return _vm.addFormConfirm('testeeFormAdd')}}},[_vm._v("确 定")])],1)],1),_c('el-dialog',{attrs:{"title":_vm.visitTitle,"visible":_vm.visibleAdd,"width":"28%","custom-class":"visit-dialog"},on:{"update:visible":function($event){_vm.visibleAdd=$event},"open":function($event){_vm.submitButtonDisabled = false}}},[_c('el-form',{ref:"visitFormAdd",attrs:{"size":"mini","model":_vm.visitFormAdd,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"当前访视:","prop":"name"}},[_c('span',[_vm._v(_vm._s(_vm.visitFormAdd.name))])]),(_vm.visitFormAdd.type === '1')?_c('el-form-item',{attrs:{"label":"计划访视时间:","prop":"id"}},[_c('span',[_vm._v(_vm._s(_vm.visitFormAdd.plannedTime ? _vm.visitFormAdd.plannedTime : "无"))])]):_vm._e(),_c('el-form-item',{attrs:{"label":"实际访视时间:","prop":"visitDate","rules":[
          { required: true, message: '请选择实际访视时间', trigger: 'blur' } ]}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"选择日期时间","value-format":"yyyy-MM-dd HH:mm:ss"},on:{"change":_vm.pickTime},model:{value:(_vm.visitFormAdd.visitDate),callback:function ($$v) {_vm.$set(_vm.visitFormAdd, "visitDate", $$v)},expression:"visitFormAdd.visitDate"}})],1),(_vm.visitFormAdd.type === '1')?_c('el-form-item',{attrs:{"label":"","prop":"id"}},[(_vm.visitFormAdd.visitDateStatus != '')?_c('span',{style:({
            color: _vm.visitFormAdd.visitDateStatus === '1' ? 'green' : 'red',
          })},[_vm._v(_vm._s(_vm.visitFormAdd.visitDateStatus === "1" ? "访视时间正常" : "访视时间超窗"))]):_vm._e()]):_vm._e()],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){_vm.visibleAdd = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"mini","type":"primary","disabled":_vm.submitButtonDisabled},on:{"click":function($event){return _vm.addVisitFormConfirm('visitFormAdd')}}},[_vm._v("保 存")])],1)],1),_c('el-dialog',{attrs:{"title":"请扫描二维码","visible":_vm.showCode,"width":"28%","custom-class":"visit-dialog"},on:{"update:visible":function($event){_vm.showCode=$event}}},[_c('div',{staticStyle:{"width":"200px","height":"200px"},attrs:{"id":"qrcode"}})]),_c('el-dialog',{attrs:{"title":"请扫描二维码","visible":_vm.showLookCode,"width":"40%","custom-class":"lookCode"},on:{"update:visible":function($event){_vm.showLookCode=$event}}},[_c('el-form',[_c('el-form-item',{attrs:{"label":"邀请码","label-width":"70px"}},[_c('el-input',{staticStyle:{"width":"70%","margin-right":"20px"},attrs:{"placeholder":"请输入内容","disabled":""},model:{value:(_vm.lookCode),callback:function ($$v) {_vm.lookCode=$$v},expression:"lookCode"}}),_c('el-button',{staticClass:"copyBtn",attrs:{"type":"text","data-clipboard-text":_vm.lookCode},on:{"click":function($event){return _vm.copy(_vm.lookCode, $event)}}},[_vm._v("复制 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }