<template>
  <div id="testeeDetail">
    <div class="testee-centreOrAdd clearfix">
      <span class="testee-centre fl">研究中心：</span>
      <el-select
        class="fl"
        v-model="centre"
        placeholder="请选择"
        size="mini"
        v-show="centreOptions.length >= 1"
        @change="centerChange"
      >
        <el-option
          v-for="item in centreOptions"
          :key="item.centerKey"
          :label="item.centerName"
          :value="item.centerKey"
        ></el-option>
      </el-select>
      <el-form :inline="true" class="demo-form-inline" style="float: right">
        <el-form-item>
          <el-input
            style="margin-top: 6px"
            placeholder="请输入内容"
            size="mini"
            v-model="search.queryVal"
            class="input-with-select"
          >
            <el-select
              style="width: 100px"
              v-model="search.selectOption"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option
                label="受试者编号"
                value="outpatientNumber"
              ></el-option>
              <el-option
                v-for="(item, index) in testeeDetail"
                :key="index"
                :label="item.pName"
                :value="item.pCode"
              >
              </el-option>
            </el-select>
            <i
              @click="searchTestee"
              slot="suffix"
              class="el-input__icon el-icon-search"
            ></i>
            <!--<el-button @click="searchTestee"  slot="append"  style="height: 24px;line-height: 24px;border: 0; border-radius: 2px;padding:1px 16px 0px;" type="primary" size="small" icon="el-icon-search"></el-button>-->
          </el-input>
        </el-form-item>

        <el-form-item>
          <!--<el-button class="testee-add " type="primary" size="small">查询</el-button>-->
          <el-button
            class="testee-add fr"
            size="small"
            type="primary"
            @click="testeeAdd"
            v-authorizeRole="{
              itemCode: 'testee_add',
              menuContainer: $authorContainer,
            }"
            >添加受试者
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="testee-content">
      <div
        style="
          width: calc(100% - 20px);
          position: absolute;
          top: 16px;
          bottom: 70px;
          overflow-y: auto;
        "
      >
        <el-table
          :data="testeeData"
          border
          size="medium"
          style="width: 100%"
          :header-cell-class-name="tableHeaderCn"
          :row-class-name="tableRowCn"
        >
          <el-table-column
            prop="outpatientNumber"
            align="center"
            label="受试者编号"
          ></el-table-column>
          <template v-for="(col, index) in cols">
            <el-table-column
              :prop="col.prop"
              :label="col.label"
              align="center"
              :key="col.prop"
            >
              <template slot-scope="scope">
                <span
                  v-if="typeof scope.row[col.prop] != 'object'"
                  :key="index"
                  >{{ formatterValue(scope.row, col.prop) }}</span
                >
                <span v-else-if="scope.row[col.prop] == null" :key="index"
                  >-</span
                >

                <span
                  v-else-if="scope.row[col.prop].length === 1"
                  :key="index"
                  @click="toHandleCommand(scope.row[col.prop], scope.row)"
                >
                  <el-tooltip
                    class="item"
                    effect="light"
                    :content="
                      '最近更新时间:' + scope.row[col.prop][0].updateTime
                    "
                    placement="bottom-start"
                  >
           
                    <template v-if="scope.row[col.prop][0].type === '1'">
           
                      <template
                        v-if="
                          groupPatientStatus(
                            scope.row['groupPatientList'],
                            scope.row[col.prop][0].groupConfigLists
                          )
                        "
                      >
                        <!--<i class="el-icon-document el-icon-document-remove"></i>-->
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#icon-yitiaoguo"></use>
                        </svg>
                      </template>
                      <!-- 已跳过 -->
                      <template v-else>
                        <!-- 实际访视时间不为空已录入-->
                        <svg
                          v-if="scope.row[col.prop][0].actualTime"
                          class="icon"
                          aria-hidden="true"
                        >
                          <use xlink:href="#icon-yiluru"></use>
                        </svg>
                        <!-- 计划时间不为空已计划  暂时去掉这个状态-->
                        <!--<svg v-else-if="scope.row[col.prop][0].plannedTime" class="icon" aria-hidden="true">
                                                  <use xlink:href="#icon-yijihua"></use>
                                                </svg>-->
                        <!-- 访视的初始状态 -->
                        <svg class="icon" aria-hidden="true" v-else>
                          <use xlink:href="#icon-weikaishi"></use>
                        </svg>
                      </template>
                    </template>
                    <!-- 非计划访视  -->
                    <template v-if="scope.row[col.prop][0].type === '0'">
                      <svg
                        v-if="scope.row[col.prop][0].actualTime"
                        class="icon"
                        aria-hidden="true"
                      >
                        <use xlink:href="#icon-yiluru"></use>
                      </svg>
                      <svg class="icon" aria-hidden="true" v-else>
                        <use xlink:href="#icon-weikaishi"></use>
                      </svg>
                    </template>
                    <!-- 通用  -->
                    <template v-if="scope.row[col.prop][0].type === '2'">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-yiluru"></use>
                      </svg>
                    </template>
                  </el-tooltip>
                </span>

                <el-dropdown
                  v-else
                  trigger="click"
                  size="small"
                  @command="handleCommand"
                >
                  <span class="el-dropdown-link">
                    <!--{{ scope.row[col.prop] }}-->
                    <!-- <i v-if="showImage(scope.row[col.prop])" class="el-icon-document testee-visitIcon"></i>-->
                    <svg
                      v-if="showImage(scope.row[col.prop])"
                      class="icon"
                      aria-hidden="true"
                    >
                      <use xlink:href="#icon-yiluru"></use>
                    </svg>
                    <svg class="icon" aria-hidden="true" v-else>
                      <use xlink:href="#icon-weikaishi"></use>
                    </svg>
                    <span>×{{ scope.row[col.prop].length }}</span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="(item, num) in scope.row[col.prop]"
                      :key="item.exampleId"
                      :command="composeValue(item, scope.row)"
                    >
                      {{
                        item.displayNameCn +
                        (num + 1) +
                        "&emsp;(最近更新时间:" +
                        item.updateTime +
                        ")"
                      }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </template>
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <!-- <el-button type="text" size="small"><i class="iconfont icon-fuzhi"></i></el-button> -->
              <div class="edit-wrap">
                <el-dropdown
                  trigger="click"
                  size="small"
                  @visible-change="getRepeatTestee"
                  @command="addRepeatTestee"
                  v-authorizeRole="{
                    itemCode: 'visit_not_plan_multiple_add',
                    menuContainer: $authorContainer,
                  }"
                >
                  <span class="el-dropdown-link">
                    <el-button type="text" class="table-btn" size="small">
                      <i class="iconfont icon-fuzhi" title="添加可重复访视"></i>
                    </el-button>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="item in addRepeatTesteeList"
                      :key="item.planCode"
                      :command="composeValue(item, scope.row)"
                      >{{ item.displayNameCn }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button
                  type="text"
                  class="table-btn"
                  size="small"
                  v-authorizeRole="{
                    itemCode: 'testee_del',
                    menuContainer: $authorContainer,
                  }"
                >
                  <el-popconfirm
                    title="您确定要删除该受试者吗？"
                    @confirm="testeeDelete(scope.row)"
                  >
                    <i
                      slot="reference"
                      class="iconfont icon-shanchu"
                      title="删除"
                    ></i>
                  </el-popconfirm>
                </el-button>
                <el-button
                  v-authorizeRole="{
                    itemCode: 'crf_data_sdv',
                    menuContainer: $authorContainer,
                  }"
                  type="text"
                  class="table-btn"
                  @click="
                    toHandleCommand(scope.row[cols[0].prop], scope.row, 'SDV')
                  "
                  >SDV
                </el-button>
                <!-- <el-popover
                                  placement="bottom-start"
                                  width="200"
                                  trigger="click"
                                  @show="getQrCode(scope.row,scope.$index)"
                                  >
                                  <div :class="'qrcode' + 'index'" style="height:200px;width:200px;"></div>
                                  <i slot="reference" class="el-icon-full-screen" style="font-size:18px;color:#2D5C88"></i>
                                  
                                </el-popover> -->
                <!-- 扫描二维码 -->
                <el-button
                  v-if="$route.query.wx != 0"
                  type="text"
                  icon="el-icon-full-screen"
                  @click="getQrCode(scope.row, scope.$index)"
                  style="font-size: 18px; color: #2d5c88"
                ></el-button>
                <!-- 查看邀请码 -->
                <el-button
                  v-if="$route.query.wx != 0"
                  type="text"
                  icon=" el-icon-reading"
                  @click="getLookCode(scope.row, scope.$index)"
                  style="font-size: 18px; color: #2d5c88"
                ></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        class="research-pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pagination.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
    <!-- 添加受试者 -->
    <el-dialog
      title="添加受试者"
      @close="handleClose('testeeFormAdd')"
      :visible.sync="dialogVisibleAdd"
      width="35%"
      custom-class="testee-dialog"
      @open="submitButtonDisabled = false"
    >
      <el-form
        size="mini"
        ref="testeeFormAdd"
        :model="testeeForm"
        :rules="testeeRulesAdd"
        label-width="80px"
      >
        <el-form-item
          label="受试者编号"
          label-width="100px"
          prop="outpatientNumber"
        >
          <el-input
            :disabled="addTesteeType === 1"
            style="width: 85%"
            :placeholder="
              addTesteeType === 1
                ? '您已选择系统生成，无需填写'
                : '请输入受试者编号'
            "
            v-model="testeeForm.outpatientNumber"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="姓名缩写" label-width="100px" prop="nameAcronym">
                    <el-input style="width:85%;" placeholder="请输入姓名缩写" v-model="testeeForm.nameAcronym"></el-input>
                    <el-tooltip class="item" effect="light" popper-class="testeeAddPopper" content="受试者姓名缩写，按照国家汉语拼音标准方案填写受试者的拼音缩写；两字姓名填写前两个字母；三字姓名填写三字首字母及第三字的第二个字母，四字姓名填写每个字的首字母。如张君ZHJU，王小鹏WXPE，欧阳小雅OYXY；如果不符合上述规则，相应字母位置可用“-”代替。" placement="bottom-end">
                        <span style="display:inline-block;width:14px;height:14px;line-height:14px;text-align:center;font-size:12px;border-radius:7px;background:#ccc;color:#fff;margin-left:4px;">?</span>
                    </el-tooltip>
                </el-form-item> -->

        <el-form-item
          v-for="item in testeeDetail"
          :key="item.pCode"
          :label="item.pName"
          label-width="100px"
          :prop="item.pCode"
        >
          <el-radio-group
            v-if="item.pCode === 'sex'"
            v-model="testeeForm[item.pCode]"
          >
            <el-radio label="01">男</el-radio>
            <el-radio label="02">女</el-radio>
            <!-- <el-radio :label="3">其他</el-radio> -->
          </el-radio-group>

          <el-select
            v-model="testeeForm[item.pCode]"
            placeholder="请选择"
            v-else-if="item.pCode === 'patientRelation'"
          >
            <el-option
              v-for="(item, index) in patientRelationSelect"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
          <el-date-picker
            type="date"
            v-else-if="
              item.pCode === 'loginTime' ||
              item.pCode === 'dob' ||
              item.pCode === 'admissionDate'
            "
            v-model="testeeForm[item.pCode]"
            placeholder="请选择日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          ></el-date-picker>
          <el-input
            v-else
            style="width: 85%"
            :placeholder="'请输入' + item.pName"
            v-model="testeeForm[item.pCode]"
          ></el-input>
          <el-tooltip
            v-if="item.pCode === 'nameAcronym'"
            class="item"
            effect="light"
            popper-class="testeeAddPopper"
            content="受试者姓名缩写，按照国家汉语拼音标准方案填写受试者的拼音缩写；两字姓名填写前两个字母；三字姓名填写三字首字母及第三字的第二个字母，四字姓名填写每个字的首字母。如张君ZHJU，王小鹏WXPE，欧阳小雅OYXY；如果不符合上述规则，相应字母位置可用“-”代替。"
            placement="bottom-end"
          >
            <span
              style="
                display: inline-block;
                width: 14px;
                height: 14px;
                line-height: 14px;
                text-align: center;
                font-size: 12px;
                border-radius: 7px;
                background: #ccc;
                color: #fff;
                margin-left: 4px;
              "
              >?</span
            >
          </el-tooltip>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in testeeForm.testeeGroup"
          :key="item.groupName"
          :prop="'testeeGroup.' + index + '.groupValue'"
          :rules="[
            {
              required: true,
              message: '请选择' + item.groupName,
              trigger: 'blur,change',
            },
          ]"
          :label="item.groupName"
          label-width="100px"
        >
          <el-select
            v-model="item.groupValue"
            :placeholder="'请选择' + item.groupName"
          >
            <el-option
              v-for="groupItem in item.data"
              :key="groupItem.code"
              :label="groupItem.dictName"
              :value="groupItem.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisibleAdd = false"
          >取 消</el-button
        >
        <el-button
          size="mini"
          type="primary"
          :disabled="submitButtonDisabled"
          @click="addFormConfirm('testeeFormAdd')"
          :loading="btnLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 添加访视 -->
    <el-dialog
      :title="visitTitle"
      :visible.sync="visibleAdd"
      width="28%"
      custom-class="visit-dialog"
      @open="submitButtonDisabled = false"
      ><!--:rules="visitRulesAdd"-->
      <el-form
        size="mini"
        ref="visitFormAdd"
        :model="visitFormAdd"
        label-width="120px"
      >
        <el-form-item label="当前访视:" prop="name">
          <span>{{ visitFormAdd.name }}</span>
        </el-form-item>
        <el-form-item
          v-if="visitFormAdd.type === '1'"
          label="计划访视时间:"
          prop="id"
        >
          <span>{{
            visitFormAdd.plannedTime ? visitFormAdd.plannedTime : "无"
          }}</span>
        </el-form-item>
        <el-form-item
          label="实际访视时间:"
          prop="visitDate"
          :rules="[
            { required: true, message: '请选择实际访视时间', trigger: 'blur' },
          ]"
        >
          <el-date-picker
            v-model="visitFormAdd.visitDate"
            type="datetime"
            placeholder="选择日期时间"
            @change="pickTime"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="visitFormAdd.type === '1'" label="" prop="id">
          <span
            v-if="visitFormAdd.visitDateStatus != ''"
            :style="{
              color: visitFormAdd.visitDateStatus === '1' ? 'green' : 'red',
            }"
            >{{
              visitFormAdd.visitDateStatus === "1"
                ? "访视时间正常"
                : "访视时间超窗"
            }}</span
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="visibleAdd = false">取 消</el-button>
        <!--<el-button v-show="visitTitle==='添加表单'" size="mini" type="primary"-->
        <!--@click="addFormConfirm('visitFormAdd')" :disabled="submitButtonDisabled">确 定</el-button>-->
        <el-button
          size="mini"
          type="primary"
          @click="addVisitFormConfirm('visitFormAdd')"
          :disabled="submitButtonDisabled"
          >保 存</el-button
        >
      </span>
    </el-dialog>

    <!-- 扫描二维码 -->
    <el-dialog
      title="请扫描二维码"
      :visible.sync="showCode"
      width="28%"
      custom-class="visit-dialog"
      ><!--:rules="visitRulesAdd"-->
      <div id="qrcode" style="width: 200px; height: 200px"></div>
    </el-dialog>

    <!-- 查看邀请码 -->
    <el-dialog
      title="请扫描二维码"
      :visible.sync="showLookCode"
      width="40%"
      custom-class="lookCode"
      ><!--:rules="visitRulesAdd"-->
      <el-form>
        <el-form-item label="邀请码" label-width="70px">
          <el-input
            v-model="lookCode"
            placeholder="请输入内容"
            disabled
            style="width: 70%; margin-right: 20px"
          ></el-input>
          <el-button
            type="text"
            @click="copy(lookCode, $event)"
            class="copyBtn"
            :data-clipboard-text="lookCode"
            >复制
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Clipboard from "clipboard";
import {
  getVisitStatus,
  getCenter,
  getTestee,
  addTestee,
  deleteTestee,
  getRepeatTestee,
  addRepeatTestee,
  getTesteeSetting,
  getCode,
  getInvitationCode,
} from "@/api/testee";
import { getVisit } from "@/api/setting";
import { getVisitPlanInfo, addPatientActualTime } from "@/api/visit";
import { Loading } from "element-ui";
import { mapState } from "vuex";
import QRCode from "qrcodejs2";
let loadingInstance = null;
export default {
  name: "Testee",
  components: {},
  data() {
    return {
      search: {
        selectOption: "",
        queryVal: "",
      },
      patientRelationSelect: [],
      firstKey: "",
      showCode: false,
      showLookCode: false,
      lookCode: "",
      isShowSdv: localStorage.getItem("user") === "CRA",
      researchName: this.$route.query.researchName,
      researchCode: this.$route.query.researchCode,
      centre: "",
      btnLoading: false,
      centreCode: "",
      centerData: {
        centerNameShow: "",
        centerKey: "",
      },
      centreOptions: [],
      searchString: "",
      cols: [],
      testeeData: [],
      dialogVisibleAdd: false,
      visibleAdd: false,
      testeeForm: {
        outpatientNumber: "",
        nameAcronym: "",
        name: "",
        sex: "",
        idNum: "",
        homephone: "",
        loginTime: "",
        cardNum: "",
        caseNum: "",
        age: "",
        dob: "",
        testeeGroup: [],
      },
      groupMessage: [],
      testeeValidate: [],
      testeeRulesAdd: {
        nameAcronym: [
          { required: true, message: "请输入姓名缩写", trigger: "change,blur" },
          { pattern: /^[A-Z]{4}$/, message: "请输入 4 位大写字母" },
        ],
        outpatientNumber: [
          {
            required: true,
            message: "请输入受试者编号",
            trigger: "change,blur",
          },
          {
            pattern: /^[A-Za-z0-9]{1,200}$/,
            message: "请输入 200 位以内数字或字母",
          },
        ],
        sex: [
          { required: true, message: "请选择性别", trigger: "blur,change" },
        ],
        idNum: [
          { required: true, message: "请输入身份证号", trigger: "change,blur" },
          {
            pattern: /^[1-9]\d{7}((0\d)|bai(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
            message: "请输入正确的身份证号码",
          },
        ],
        homephone: [
          { required: true, message: "请输入手机号", trigger: "change,blur" },
          { pattern: /^1[3456789]\d{9}$/, message: "请输入正确的手机号码" },
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "change,blur" },
          { min: 1, max: 25, trigger: "change,blur" },
          // {
          //     pattern: /(?:[\u4E00-\u9FFF]{1,8}·bai\u4E00-\u9FFF]{1,8})|(?:[\u4E00-\u9FFF]{2,5})/,
          //     message: '请输入正确的姓名'
          // }
        ],
        cardNum: [
          { required: true, message: "请输入就诊卡号", trigger: "change,blur" },
          { pattern: /^[0-9a-zA-Z]{1,25}$/, message: "请输入正确的就诊卡号" },
        ],
        age: [
          { required: true, message: "请输入年龄", trigger: "change,blur" },
          { pattern: /^\d{1,3}$/, message: "请输入正确的年龄" },
        ],
        caseNum: [
          { required: true, message: "请输入病案号", trigger: "change,blur" },
          { pattern: /^[0-9a-zA-Z]{1,25}$/, message: "请输入正确的病案号" },
        ],
        loginTime: [
          { required: true, message: "请选择登记日期", trigger: "change,blur" },
        ],
        dob: [
          { required: true, message: "请选择出生日期", trigger: "change,blur" },
        ],
      },
      addTesteeType: "",
      testeeDetail: [],
      addRepeatTesteeList: [],
      pagination: {
        currentPage: 1,
        total: 0,
        size: 10,
      },
      submitButtonDisabled: false,
      visitTitle: "添加访视",
      visitFormAdd: {
        visitDate: "",
        name: "",
        type: "",
        plannedTime: "",
        visitDateStatus: "",
        //访视窗之前时间
        visitDateBefor: "",
        //访视窗之后时间
        visitDateAfter: "",
        sCode: "",
        patientId: "",
        sGroup: "",
      },
      codeUrl: "",
      patientRelationSelect: [],
    };
  },
  computed: {
    ...mapState(["versionData", "versionFineshed"]),
  },
  watch: {
    versionFineshed: function (n, o) {
      console.log(n != o);
      if (n != o) {
        this.getCenterList();
        this.getTesteeSettingInfo();
      }
    },
  },
  created() {
    loadingInstance = Loading.service({
      fullscreen: true,
      text: "Loading",
    });
    this.getCenterList();
    this.getTesteeSettingInfo();
  },
  mounted() {},
  methods: {
    // 搜索
    searchTestee() {
      let queryKey = this.search.selectOption;
      let queryVal = this.search.queryVal;
      this.getTesteeList(this.centre, "searchTestee");
    },
    formatterValue(row, col) {
      if (col !== "patientRelation") {
        return row[col];
      } else {
        return (
          (this.patientRelationSelect &&
            this.patientRelationSelect[parseInt(row[col])]) ||
          row[col]
        );
      }
      console.log(row, col);
    },
    //复制
    copy() {
      let clipboard = new Clipboard(".copyBtn");
      console.log(clipboard);
      clipboard.on("success", (e) => {
        this.$message.success("复制成功");
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message.error("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    lookcode() {
      return "lookCodeStyle";
    },
    // 查看邀请码
    async getLookCode(row, index) {
      const { pkId } = row;
      const { researchCode } = this.$route.query;
      const versionCode = JSON.parse(localStorage.getItem("versionCode"));
      if (pkId) {
        try {
          let result = await getInvitationCode(researchCode, versionCode, pkId);
          if (result.code === 200) {
            this.showLookCode = true;
            this.lookCode = result.data;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    code() {
      this.$nextTick(() => {
        let qr = document.getElementById("qrcode");
        document.getElementById("qrcode").innerHTML = "";
        let qrCode = new QRCode(qr, {
          width: 200,
          height: 200,
          text: this.codeUrl,
          colorDark: "#000",
          colorLight: "#fff",
        });
      });
    },
    //获取二维码
    async getQrCode(row, index) {
      const { pkId } = row;
      const { researchCode } = this.$route.query;
      const versionCode = JSON.parse(localStorage.getItem("versionCode"));
      if (pkId) {
        try {
          let result = await getCode(researchCode, versionCode, pkId);
          if (result.code === 200) {
            this.showCode = true;
            this.codeUrl = result.data;
            this.code();
          }
        } catch (error) {}
      }
    },
    getTesteeSettingInfo() {
      const postData = {
        researchCode: this.$route.query.researchCode,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      getTesteeSetting(postData)
        .then((res) => {
          console.log("getTesteeSetting", JSON.stringify(res));
          this.testeeDetail = res.data.lable;
          this.addTesteeType = res.data.createFlag;
          this.testeeValidate = res.data.lable.reduce(function (total, item) {
            total.push(item.pCode);
            return total;
          }, []);
          //                      this.groupMessage = res.data.groupMessage;
          this.groupMessage = res.data.groupMessage.reduce(function (
            total,
            item
          ) {
            item.groupValue = "";
            total.push(item);
            return total;
          },
          []);
          this.groupMessage.forEach((item, index) => {
            this.testeeValidate.push("testeeGroup." + index + ".groupValue");
          });
        })
        .catch((error) => {});
    },
    //获取可重复访视表单
    getRepeatTestee(show) {
      const postData = {
        researchCode: this.researchCode,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      show &&
        getRepeatTestee(postData)
          .then((res) => {
            // console.log('getRepeatTestee', res);
            this.addRepeatTesteeList = res.data;
          })
          .catch((error) => {});
      console.log(this.addRepeatTesteeList);
    },
    //新增可重复访视
    addRepeatTestee(command) {
      const postData = {
        patientId: command.row.pkId,
        sectionCode: command.item.code,
        registryEntityCode: command.item.registryEntityCode,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      addRepeatTestee(postData)
        .then((res) => {
          this.getTesteeList(this.centre);
        })
        .catch((error) => {});
    },
    //获取中心列表
    getCenterList(val) {
      //      console.log(JSON.parse(localStorage.getItem('versionCode')));
      const postData = {
        researchCode: this.researchCode,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };

      getCenter(postData)
        .then((res) => {
          // console.log('getCenter', res);
          this.centreOptions = res.data.dataList;
          if (this.centreOptions.length === 0) {
            loadingInstance.close();
            return;
          }
          this.centerData = this.centreOptions[0];
          this.centerData.centerNameShow = this.centreOptions[0].centerName;
          this.centre = this.centreOptions[0].centerKey;
          this.centreCode = this.centreOptions[0].centerCode;
          this.getTesteeList(this.centre);
          loadingInstance.close();
        })
        .catch((error) => {
          loadingInstance.close();
        });
    },
    centerChange(val) {
      this.getTesteeList(val);
      let centerJson = this.centreOptions.filter(
        (item) => item.centerKey === val
      )[0];
      this.centerData.centerNameShow = centerJson.centerName;
      this.centreCode = centerJson.centerCode;
    },
    //获取table数据
    getTesteeList(centerKey, val) {
      const postData = {
        researchCode: this.researchCode,
        centerKey: centerKey,
        pageIndex: this.pagination.currentPage,
        range: this.pagination.size,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      // 增加搜索参数
      if (val) {
        let queryKey = this.search.selectOption;
        let queryVal = this.search.queryVal;
        postData[queryKey] = queryVal;
      }
      console.log(JSON.stringify(postData));
      getTestee(postData)
        .then((res) => {
          console.log("getTestee", res);
          this.firstKey = res.data.first;
          this.testeeData = res.data.dataList;
          this.cols = res.data.cols;
          this.pagination.total = res.data.count;
          this.dialogVisibleAdd = false;
          this.patientRelationSelect = res.data.patientRelationSelect;
          loadingInstance.close();
        })
        .catch((error) => {
          this.testeeData = [];
          loadingInstance.close();
        });
    },
    //获取受试者规则
    getVisitInfo() {
      getVisit({
        researchCode: this.researchCode,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      })
        .then((res) => {
          console.log("getVisit", res);
          this.patientRelationSelect = res.data.patientRelationSelect;
        })
        .catch((error) => {
          this.submitButtonDisabled = false;
        });
    },
    //添加受试者
    testeeAdd() {
      this.getVisitInfo();
      console.log(this.$options);
      this.testeeForm = this.$options.data.call(this).testeeForm;
      console.log(this.$options.data.call(this).testeeForm, this.groupMessage);
      this.testeeForm.testeeGroup = this.groupMessage;
      //              .reduce(function (total, item) {
      //                  item.groupValue = 'aaaa';
      //                  total.push(item);
      //                  return total
      //              }, []);

      this.$refs.testeeFormAdd && this.$refs.testeeFormAdd.clearValidate();
      this.dialogVisibleAdd = true;
    },
    addFormConfirm(formName) {
      this.btnLoading = true;
      let validateDetail =
        this.addTesteeType === 1
          ? this.testeeValidate
          : this.testeeValidate.concat(["outpatientNumber"]);
      let isValid = true;
      let countValid = 0;
      let _this = this;
      //只有一个自动生成的编号是无需检验
      if (validateDetail.length > 0) {
        this.$refs[formName].validateField(validateDetail, (result) => {
          if (!!result) {
            isValid = false;
            this.btnLoading = false;
            return false;
          } else {
            countValid++;
          }
          if (isValid && countValid == validateDetail.length) {
            console.log("testeeForm" + JSON.stringify(_this.testeeForm));
            let postD = validateDetail.reduce(function (total, item) {
              total[item] = _this.testeeForm[item];
              return total;
            }, {});
            // this.submitButtonDisabled = true;
            const postData = {
              researchCode: this.researchCode,
              versionCode: JSON.parse(localStorage.getItem("versionCode")),
              centerKey: this.centre,
              centerCode: this.centreCode,
              ...postD,
            };
            this.addTesteeCommon(postData);
          }
        });
      } else {
        const postData = {
          researchCode: this.researchCode,
          versionCode: JSON.parse(localStorage.getItem("versionCode")),
          centerKey: this.centre,
          centerCode: this.centreCode,
        };
        this.addTesteeCommon(postData);
      }
    },
    addTesteeCommon(postData) {
      let groupMsg = this.testeeForm.testeeGroup.reduce(function (total, item) {
        return total + item.groupValue + ",";
      }, "");
      postData.groupMessage = groupMsg;
      addTestee(postData)
        .then((res) => {
          this.getTesteeList(this.centre);
          this.$message({
            type: "success",
            showClose: true,
            message: res.data,
            center: true,
          });
          this.btnLoading = false;
        })
        .catch((error) => {
          this.submitButtonDisabled = false;
          this.btnLoading = false;
        });
    },
    handleClose(formName) {
      this.$refs[formName].resetFields();
    },
    testeeDelete(col) {
      const postData = {
        // pkId: 5247
        pkId: col.pkId,
      };
      deleteTestee(postData)
        .then((res) => {
          this.getTesteeList(this.centre);
          this.$message({
            type: "success",
            showClose: true,
            message: res.data,
            center: true,
          });
        })
        .catch((error) => {});
    },
    tableCellClick(cellData) {},
    //重复表单的选择
    handleCommand(command, type) {
      console.log("受试者列表跳转form", command, type);
      this.initData(command.item, command.row);
      //实际访视时间不为空直接进到病人访视录入
      if (
        command.item.type === "2" ||
        type === "SDV" ||
        this.visitFormAdd.visitDate
      ) {
 
        this.$router.push({
          name: "FormEntry",
          query: {
            groupConfigLists: command.row.groupPatientList
              ? JSON.stringify(command.row.groupPatientList)
              : "",
            registryEntityCode: command.item.registryEntityCode,
            researchCode: this.researchCode,
            researchName: this.researchName,
            parentCode: command.item.code,
            patientId: command.item.patientId,
            exampleId: command.item.exampleId,
            centerKey: this.centre,
            testeeName: command.row.name,
            actualTime:command.item.actualTime,
            centerName: this.centerData.centerNameShow,
            outpatientNumber: command.row.outpatientNumber,
            versionCode: JSON.parse(localStorage.getItem("versionCode")),
          },
        });
      } else {
        //                    this.visitFormAdd.name=command.item.displayNameCn
        this.visibleAdd = true;
      }
    },
    toHandleCommand(item, row, type) {
      if (type && type === "SDV") {
        this.handleCommand({ item: row[this.firstKey][0], row: row }, type);
        //                    this.handleCommand({'item': item[0], 'row': row});
      } else {
        let visitItem = item[0];
        //已跳过
        if (visitItem.type === "1") {
          let groupFlag = this.groupPatientStatus(
            row.groupPatientList,
            visitItem.groupConfigLists
          );
          if (groupFlag) {
            return;
          }
        }
        //                    this.initData(visitItem,row)
        //                    if(this.visitFormAdd.visitDate){
        //                        // 填写过访视时间直接进到访视录入
        this.handleCommand({ item: item[0], row: row });
        //                    }else{
        //                        this.visibleAdd=true;
        //                    }
      }
    },
    groupPatientStatus(patientGroup, visitPlanPatientGroup) {
      //groupFlag == true 适于某个受试者分组无需进行此访视
      let groupFlag = false;
      let count = 0;
      if (patientGroup) {
        patientGroup.forEach((val, index) => {
          if (
            visitPlanPatientGroup &&
            visitPlanPatientGroup.indexOf(val) > -1
          ) {
            count++;
          }
        });
        // 匹配全部组返回true，跳过访视
        if (count != 0 && count == visitPlanPatientGroup.length) {
          groupFlag = true;
        }
      }
      return groupFlag;
    },
    initData(visitItem, row) {
      const resData = {
        patientId: row.pkId,
        registryEntityCode: visitItem.registryEntityCode,
        sCode: visitItem.code,
        versionCode: JSON.parse(localStorage.getItem("versionCode")),
      };
      getVisitPlanInfo(resData).then((res) => {
        this.visitFormAdd.plannedTime = res.data.plannedTime;
        this.visitFormAdd.visitDateAfter = res.data.max ? res.data.max : "";
        this.visitFormAdd.visitDateBefor = res.data.min ? res.data.min : "";
      });
      //填写访视
      this.visitFormAdd = this.$options.data.call(this).visitFormAdd;
      this.$refs.visitFormAdd && this.$refs.visitFormAdd.clearValidate();
      this.visitFormAdd.name = visitItem.name;
      this.visitFormAdd.type = visitItem.type;
      this.visitFormAdd.sCode = visitItem.code;
      this.visitFormAdd.patientId = row.pkId;
      this.visitFormAdd.visitDate = visitItem.actualTime
        ? visitItem.actualTime
        : "";
      this.visitFormAdd.sGroup = visitItem.groupConfigLists
        ? visitItem.groupConfigLists.join(",")
        : "";
    },
    composeValue(item, row) {
      return {
        item: item,
        row: row,
      };
    },
    showImage(item) {
      let groupFlag = false;
      for (let i = 0; i < item.length; i++) {
        if (item[i].actualTime) {
          groupFlag = true;
          break;
        }
      }
      return groupFlag;
    },

    addVisitFormConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let postData = {
            patientId: this.visitFormAdd.patientId,
            registryEntityCode: this.researchCode,
            sCode: this.visitFormAdd.sCode,
            status: this.visitFormAdd.visitDateStatus,
            actualTime: this.visitFormAdd.visitDate,
            sName: this.visitFormAdd.name,
            sGroup: this.visitFormAdd.sGroup,
            versionCode: JSON.parse(localStorage.getItem("versionCode")),
          };
          addPatientActualTime(postData)
            .then((res) => {
              this.$message({
                type: "success",
                showClose: true,
                message: res.msg,
                center: true,
              });
              this.getTesteeList(this.centre);
              this.visibleAdd = false;
            })
            .catch((error) => {
              this.visibleAdd = false;
            });
        } else {
          //                        console.log('error submit!!');
          return false;
        }
      });
    },
    pickTime(val) {
      if (
        this.visitFormAdd.visitDateBefor &&
        this.visitFormAdd.visitDateAfter
      ) {
        let visitDateBefor = Date.parse(this.visitFormAdd.visitDateBefor);
        let visitDateAfter = Date.parse(this.visitFormAdd.visitDateAfter);
        let visitDate = Date.parse(val);
        if (visitDate >= visitDateBefor && visitDate <= visitDateAfter) {
          this.visitFormAdd.visitDateStatus = "1";
        } else {
          this.visitFormAdd.visitDateStatus = "2";
        }
      }
    },
    //分页
    handleSizeChange(size) {
      this.pagination.size = size;
      this.getTesteeList(this.centre);
    },
    handleCurrentChange(pageNum) {
      this.pagination.currentPage = pageNum;
      this.getTesteeList(this.centre);
    },
    tableHeaderCn() {
      return "testee-table_header";
    },
    tableRowCn() {
      return "testee-table_row";
    },
    //表格单元搜索
    tableCellCom(data) {
      return data.filter(
        (item) => item.planName.indexOf(this.searchString) != -1
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    loadingInstance && loadingInstance.close();
    next();
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  width: 200px;
}

/deep/ .el-table thead {
  color: #000 !important;
}

.table-btn {
  color: #2c5c89 !important;

  i {
    font-size: 20px !important;
  }
}

#qrcode {
  display: block;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%,-50%);
  margin: 0 auto;
  width: 300px;
  height: 300px;
  img {
    width: 200px;
    height: 200px;
    background-color: #fff; //设置白色背景色
    padding: 6px; // 利用padding的特性，挤出白边
  }
}

#testeeDetail {
  // height: 100%;
  // background:  rgba(73,150,255,0.14);
  .testee-centreOrAdd {
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    padding-top: 8px;

    .testee-centre {
      height: 16px;
      font-size: 16px;
      color: #212121;
      margin-left: 16px;
    }

    .testee-add {
      padding: 0 16px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      color: #fff;
      background: #2c5c89;
      border-radius: 2px;
      margin: 8px 16px;
      border: none;
    }
  }

  .testee-content {
    position: absolute;
    top: 140px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    background: #fff;
    padding: 0 10px;
    overflow-y: auto;

    .testee-visitIcon {
      font-size: 24px;
      vertical-align: sub;
      color: #ccc;
    }

    .research-pagination {
      position: fixed;
      z-index: 15;
      bottom: 24px;
      right: 32px;
    }
  }
}
::v-deep .el-dialog__body {
  min-height: 100px;
  position: relative;
}
</style>
<style lang="scss">
.testee-table_header {
  height: 48px;
  background-color: #eaecef !important;
  border-right: none !important;

  .cell {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
}

.testee-cell_search {
  padding: 0 15px 8px;
  width: auto;
  border-bottom: 1px solid #e4e4e4;

  .el-input__suffix {
    right: 20px;
  }
}

.testee-table_row td {
  height: 42px;
  padding: 0;
}

.el-dialog.testee-dialog {
  min-width: 270px;
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner,
  .el-select {
    width: 85%;
  }
}

.testeeAddPopper {
  width: 20%;
}

.edit-wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
.icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
